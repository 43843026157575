<template>
  <dialog
    v-if="consentStatus === null && isdelayed"
    id="consent-back-drop"
    class="flex w-full h-full p-3"
    open
  >
    <div class="m-auto bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full text-sm h-min">
      <div class="p-4 pb-2 text-gray-700">
        <p class="font-semibold mb-2">
          ความยินยอมในการเก็บข้อมูล
        </p>
        <p class="">
          เพื่อเพิ่มประสิทธิภาพ และประสบการณ์ที่ดีในการใช้งานเว็บไซต์ คุณสามารถเลือกตั้งค่าความยินยอมการเก็บข้อมูล โดยคลิก "การตั้งค่า" <a
            class="text-sky-500 hover:text-sky-600 transition duration-500 underline"
            :href="`${webUrl}/privacy-policy`"
            target="_blank"
          >นโยบายความเป็นส่วนตัว</a>
        </p>
      </div>
      <div class="p-4 pt-2 text-right">
        <button
          type="button"
          class="py-2 px-8 border border-sky-500 text-sky-500 rounded-full mr-2"
          @click="onDeny"
        >
          ปฏิเสธ
        </button>
        <button
          id="consent-accept"
          type="button"
          class="py-2 px-8 bg-sky-500 text-white rounded-full hover:bg-sky-600 transition duration-500"
          @click="onConsent"
        >
          ยอมรับ
        </button>
      </div>
      <div class="bg-gray-100 p-2">
        <img
          class="w-20"
          src="@/assets/logo-with-name.svg"
          alt=""
        >
      </div>
    </div>
  </dialog>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { useConsentStoreRefs } from '@/store/refs/consent.js'
const emit = defineEmits(['user-consent'])
const { consentStatus } = useConsentStoreRefs()
const isdelayed = ref(false)
const webUrl = ref(import.meta.env.VITE_WEB_URL)

onMounted(() => {
  setTimeout(() => {
    isdelayed.value = true
  }, 500)
})

const onConsent = async () => {
  emit('user-consent', 'accepted')
  consentStatus.value = 'accepted'
}

const onDeny = async () => {
  emit('user-consent', 'denied')
  consentStatus.value = 'denied'
}
</script>
<style scoped>
#consent-back-drop{
  z-index: 1000;
}

dialog {
  background: rgba(0,0,0,0.6);
  backdrop-filter: blur(1.5px);
}
</style>
