import { Preferences } from '@capacitor/preferences'
import { Capacitor } from '@capacitor/core'

const isPreferencesAvailable = Capacitor.isPluginAvailable('Preferences')

export const setPref = async (key, value) => {
  if (isPreferencesAvailable) {
    return Preferences.set({
      key,
      value: JSON.stringify(value)
    })
  }
}

export const removePref = async (key) => {
  if (isPreferencesAvailable) {
    return Preferences.remove({
      key
    })
  }
}

export const getPref = async (key) => {
  if (isPreferencesAvailable) {
    const { value } = await Preferences.get({ key })
    return JSON.parse(value)
  }
  return false
}

export const cleanPerfData = async () => {
  await removePref('batteryLevel')
  await removePref('car')
  await removePref('batteryRange')
  await removePref('filter')
  await removePref('user')
  await removePref('savePlace')
}
