import { storeToRefs, defineStore } from 'pinia'
import { ref } from 'vue'

export const useLangStore = defineStore('lang', () => {
  const currentLang = ref(null)

  return { currentLang }
}, {
  persist: { enabled: true }
})

export function useLangStoreRefs () {
  const langStore = useLangStore()
  return storeToRefs(langStore)
}
