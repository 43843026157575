import { storeToRefs, defineStore } from 'pinia'
import { ref } from 'vue'

export const useConsentStore = defineStore('consent', () => {
  const consentStatus = ref(null)
  return { consentStatus }
}, {
  persist: { enabled: true }
})

export function useConsentStoreRefs () {
  const consentStore = useConsentStore()
  return storeToRefs(consentStore)
}
