import { getCookie } from '@/cookie'
import { Capacitor } from '@capacitor/core'
export const isAndroid = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  return /android/i.test(userAgent)
}

export const isIOS = () => {
  const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'

  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
}

export const isTesla = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  return /tesla/i.test(userAgent)
}

export const getCurrentFormFactor = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (Capacitor.getPlatform() === 'ios' && Capacitor.isNativePlatform()) {
    return 'IOS_NATIVE_CAPACITOR'
  }

  if (Capacitor.getPlatform() === 'android' && Capacitor.isNativePlatform()) {
    return 'ANDROID_NATIVE_CAPACITOR'
  }

  if (userAgent.includes('Line/')) {
    return 'LINE'
  }

  if (getCookie('app-platform') === 'iOS App Store') {
    return 'IOS_NATIVE'
  }

  if (document.referrer.startsWith('android-app://')) {
    return 'ANDROID_NATIVE'
  }

  if (window.matchMedia('(display-mode: standalone)').matches) {
    if (isIOS()) {
      return 'IOS_PWA'
    }

    if (isAndroid()) {
      return 'ANDROID_PWA'
    }

    return 'UNKNOWN_PWA'
  } else {
    if (isIOS()) {
      return 'IOS_WEB'
    }

    if (isAndroid()) {
      return 'ANDROID_WEB'
    }

    if (isTesla()) {
      return 'TESLA_WEB'
    }

    return 'WEB'
  }
}
