import { storeToRefs, defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useCarStore = defineStore('car', () => {
  const chargeState = ref({
    batteryLevel: 80,
    batteryRange: 300
  })
  const averageDCPower = ref(80)
  const batteryCapacity = ref(61)
  const currentCarBrand = ref('')
  const currentCarModel = ref(null)
  const currentBatteryCapacity = computed(() => batteryCapacity.value * (chargeState.value.batteryLevel / 100))
  return { chargeState, averageDCPower, batteryCapacity, currentBatteryCapacity, currentCarBrand, currentCarModel }
}, {
  persist: { enabled: true }
})

export function useCarStoreRefs () {
  const carStore = useCarStore()
  return storeToRefs(carStore)
}
