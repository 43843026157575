import { initializeApp } from 'firebase/app'
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { FirebaseAnalytics } from '@capacitor-firebase/analytics'
import { Capacitor } from '@capacitor/core'
import { getCookie } from '@/cookie'
import { LineLogin } from 'capacitor-line-login'
import axios from 'axios'
import {
  getAuth,
  connectAuthEmulator,
  setPersistence,
  browserLocalPersistence
} from 'firebase/auth'
let firebaseConfig = {}

let analytics = true
let auth
let appInit = false
const initFirebase = async (consentStatus) => {

  if (!Capacitor.isNativePlatform()) {
    if(import.meta.env.MODE !== 'development' && import.meta.env.DEV === false) {
      const { data } = await axios.get('/__/firebase/init.json')
      firebaseConfig = data
      firebaseConfig.authDomain = 'saifah.app'
    } else {
      firebaseConfig = {
        apiKey: 'AIzaSyApHOZ7ayQ1fOZNBFg_t61lYfAa_NDFfts',
        authDomain: 'beta.saifah.app',
        projectId: 'saifah-staging',
        storageBucket: 'saifah-staging.appspot.com',
        messagingSenderId: '26941688655',
        appId: '1:26941688655:web:a6d36120bddc82cf59933a',
        measurementId: 'G-Z0Z6H7EP9N'
      }
    }

    if(import.meta.env.VITE_WHITE_LABEL_MID) {
      firebaseConfig.measurementId = import.meta.env.VITE_WHITE_LABEL_MID
    }

    initializeApp(firebaseConfig)
    auth = getAuth()
  }

  if (import.meta.env.MODE === 'development' && !!auth) {
    connectAuthEmulator(auth, import.meta.env.VITE_FIREBASE_EMULATOR || 'http://localhost:9099')
  }

  if (consentStatus === 'accepted') {
    await FirebaseAnalytics.setEnabled({
      enabled: true
    })
    analytics = true
  }

  appInit = true
}

const login = async (userService) => {
  await setPersistenceAuth()
  let mode
  let loginUser

  if (getCookie('app-platform') === 'iOS App Store') {
    mode = 'redirect'
  } else {
    mode = 'popup'
  }

  try {
    if (userService === 'apple') {
      loginUser = await FirebaseAuthentication.signInWithApple({
        mode
      })
    }
  
    if (userService === 'google') {
      loginUser = await FirebaseAuthentication.signInWithGoogle({
        mode
      })
    }
  
    if (userService === 'facebook') {
      loginUser = await FirebaseAuthentication.signInWithFacebook({
        mode
      })
    }

    if (userService === 'line') {
      
      LineLogin.setup({ channelId: '1661081949' })

      const { data } = await LineLogin.login()

      const { data: responseData } = await axios.post(`${import.meta.env.VITE_API_PATH}/createLineUser`, {
        lineToken: data.accessToken,
        profile: data
      })

      loginUser = await loginWithToken(responseData.firebaseCustomToken)
      
    }             

  } catch (error) {
    return false
  }

  return loginUser
}

const signOut = async () => {
  await FirebaseAuthentication.signOut()
}

const getIdToken = async () => {
  const { token } = await FirebaseAuthentication.getIdToken()
  return token
}

const currentUser = async () => {
  try {
    const result = await FirebaseAuthentication.getCurrentUser()
    return result.user
  } catch (e) {
    return null
  }
}

const loginAnonymous = async () => {
  await setPersistenceAuth()
  const result = await FirebaseAuthentication.getCurrentUser()
  if (result.user) {
    return result
  }
  const loginUser = await FirebaseAuthentication.signInAnonymously()

  return loginUser
}

const loginWithToken = async (token) => {
  await setPersistenceAuth()
  try {
    const loginUser = await FirebaseAuthentication.signInWithCustomToken({
      token
    })
    return loginUser
  } catch (error) {
    await signOut()
  }
}

const deleteUserFromDb = async () => {
  return FirebaseAuthentication.deleteUser()
}

const setPersistenceAuth = async () => {
  if (!Capacitor.isNativePlatform()) {
    await setPersistence(auth, browserLocalPersistence)
  }
}

const logEvent = async (name, params) => {
  if (appInit) {
    if (analytics) {
      if (import.meta.env.MODE === 'development') {
        console.log({
          name,
          params
        })
      }
      await FirebaseAnalytics.logEvent({
        name,
        params
      })
    }
  }
}

export {
  analytics,
  logEvent,
  login,
  loginWithToken,
  loginAnonymous,
  deleteUserFromDb,
  currentUser,
  getIdToken,
  signOut,
  auth,
  initFirebase
}
