import { storeToRefs, defineStore } from 'pinia'
import { ref } from 'vue'

export const useWhiteLabelStore = defineStore('whiteLabel', () => {
  const brand = ref('saifah')
  return { brand }
})

export function useWhiteLabelStoreRefs () {
  const whiteLabelStore = useWhiteLabelStore()
  return storeToRefs(whiteLabelStore)
}
